<template>
  <div class="container">
    <h1 class="mb-4 font-bold text-blue-600"><CogIcon class="h-5 w-5 inline-block"/> <span class="align-middle">Configuration</span></h1>
    <label for="notification_push" class="block mb-1">Acceptez-vous les notifications push ?</label>
    <select id="notification_push" @change="toggleSubscription" class="block border w-full">
      <option value="0" :selected="!config.push">Non</option>
      <option value="1" :selected="config.push">Oui</option>
    </select>
  </div>
</template>

<script lang="ts">
import 'tailwindcss/tailwind.css'
import { defineComponent } from 'vue'
import { save, get } from '@/service/configuration'
import { CogIcon } from '@heroicons/vue/solid'

const applicationServerKey = 'BMBlr6YznhYMX3NgcWIDRxZXs0sh7tCv7_YCsWcww0ZCv9WGg-tRCXfMEHTiBPCksSqeve1twlbmVAZFv7GSuj0'

export default defineComponent({
  name: 'Configuration',
  components: {
    CogIcon
  },
  setup () {
    const config = get()

    return {
      config,
      toggleSubscription: (event: any) => {
        if (event.target.value === '1') {
          pushSubscribe()
          save({
            push: true
          })
        } else {
          pushUnsubscribe()
          save({
            push: false
          })
        }
      }
    }
  }
})

function pushSubscribe () {
  console.log('push is subscribe')

  return checkNotificationPermission()
    .then(() => navigator.serviceWorker.ready)
    .then(serviceWorkerRegistration =>
      serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(applicationServerKey)
      })
    )
    .then(subscription => {
      // Subscription was successful
      // create subscription on your server
      return pushSendSubscriptionToServer(subscription, 'POST')
    })
    .catch(e => {
      if (Notification.permission === 'denied') {
        // The user denied the notification permission which
        // means we failed to subscribe and the user will need
        // to manually change the notification permission to
        // subscribe to push messages
        console.warn('Notifications are denied by the user.')
      } else {
        // A problem occurred with the subscription; common reasons
        // include network errors or the user skipped the permission
        console.error('Impossible to subscribe to push notifications', e)
      }
    })
}

function pushUnsubscribe () {
  console.log('push is unsubscribe')

  // To unsubscribe from push messaging, you need to get the subscription object
  navigator.serviceWorker.ready
    .then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
    .then(subscription => {
      // Check that we have a subscription to unsubscribe
      if (!subscription) {
        // No subscription object, so set the state
        // to allow the user to subscribe to push
        return
      }

      // We have a subscription, unsubscribe
      // Remove push subscription from server
      return pushSendSubscriptionToServer(subscription, 'DELETE')
    })
    .then(subscription => subscription.unsubscribe())
    .catch(e => {
      // We failed to unsubscribe, this can lead to
      // an unusual state, so  it may be best to remove
      // the users data from your data store and
      // inform the user that you have done so
      console.error('Error when unsubscribing the user', e)
    })
}

function pushSendSubscriptionToServer (subscription: any, method: string) {
  const key = subscription.getKey('p256dh')
  const token = subscription.getKey('auth')
  const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0]

  return fetch('https://lens.scheuren.me/push_subscription.php', {
    method,
    body: JSON.stringify({
      endpoint: subscription.endpoint,
      publicKey: key ? btoa(String.fromCharCode.apply(null, [...new Uint8Array(key)])) : null,
      authToken: token ? btoa(String.fromCharCode.apply(null, [...new Uint8Array(token)])) : null,
      contentEncoding
    })
  }).then(() => subscription)
}

function urlBase64ToUint8Array (base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

function checkNotificationPermission () {
  return new Promise((resolve, reject) => {
    if (Notification.permission === 'denied') {
      return reject(new Error('Push messages are blocked.'))
    }

    if (Notification.permission === 'granted') {
      return resolve('void')
    }

    if (Notification.permission === 'default') {
      return Notification.requestPermission().then(result => {
        if (result !== 'granted') {
          reject(new Error('Bad permission result'))
        } else {
          resolve('void')
        }
      })
    }

    return reject(new Error('Unknown permission'))
  })
}
</script>
