import { Configuration } from '@/models/configuration'

const key = 'lens_config'
export const defaults = {
  push: false
}

export function save (config: Configuration): void {
  localStorage.setItem(key, JSON.stringify(config))
}

export function get (): Configuration {
  if (checkIfStorageIsSupported()) {
    const config = localStorage.getItem(key)
    if (config !== null) {
      return JSON.parse(config)
    }
  }

  return defaults
}

function checkIfStorageIsSupported () {
  const test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}
